<template>
  <div>
    <b-row>
      <b-col
        v-if="offset1"
        md="3"
      />
      <b-col
        v-for="(field, index) in fields"
        :key="index"
        md="3"
      >
        <label :for="field.inputId">{{ field.label }}</label>
        <b-form-group v-if="field.type === 'select'">
          <v-select
            :id="field.inputId"
            v-model="field.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="field.options"
            :selectable="option => !option.value.includes('select_value')"
            :placeholder="field.placeholder"
            label="text"
          />
          <!--            <select-->
          <!--              :id="field.inputId"-->
          <!--              v-model="field.value"-->
          <!--            >-->
          <!--              <option-->
          <!--                v-for="(option, optionIndex) in field.options"-->
          <!--                :key="optionIndex"-->
          <!--                :value="option.value"-->
          <!--              >-->
          <!--                {{ option.label }}-->
          <!--              </option>-->
          <!--            </select>-->
        </b-form-group>
        <b-input-group
          v-else
          class="mb-1"
        >
          <b-form-input
            :id="field.inputId"
            v-model="field.value"
            :type="field.type"
            :placeholder="field.placeholder"
            autocomplete="off"
            show-decade-nav
          />
          <b-input-group-append v-if="field.date">
            <b-form-datepicker
              v-model="field.value"
              show-decade-nav
              button-only
              right
              locale="fr-FR"
              :aria-controls="field.inputId"
              selected-variant="primary"
              @context="onContext"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BFormGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  name: 'ListeFilter',
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormDatepicker,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    offset1: {
      type: Boolean,
      value: false,
    },
  },
  data() {
    return {
      formatted: '',
      selected: '',
    }
  },
  methods: {
    reset() {
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    addField() {
      this.fields.push({
        inputId: `input${this.fields.length}`,
        label: `Champ ${this.fields.length + 1}`,
        value: '',
        type: 'text',
        placeholder: 'YYYY-MM-DD',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
